var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { "fill-height": "", fluid: "", "grid-list-xl": "" } },
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { sm6: "", xs12: "", md6: "", lg3: "" } },
            [
              _c("material-stats-card", {
                attrs: {
                  color: "green",
                  icon: "mdi-store",
                  title: "Revenue",
                  value: "$34,245",
                  "sub-icon": "mdi-calendar",
                  "sub-text": "Last 24 Hours",
                },
              }),
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { sm6: "", xs12: "", md6: "", lg3: "" } },
            [
              _c("material-stats-card", {
                attrs: {
                  color: "orange",
                  icon: "mdi-content-copy",
                  title: "Used Space",
                  value: "49/50",
                  "small-value": "GB",
                  "sub-icon": "mdi-alert",
                  "sub-icon-color": "error",
                  "sub-text": "Get More Space...",
                  "sub-text-color": "text-primary",
                },
              }),
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { sm6: "", xs12: "", md6: "", lg3: "" } },
            [
              _c("material-stats-card", {
                attrs: {
                  color: "red",
                  icon: "mdi-information-outline",
                  title: "Fixed Issues",
                  value: "75",
                  "sub-icon": "mdi-tag",
                  "sub-text": "Tracked from Github",
                },
              }),
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { sm6: "", xs12: "", md6: "", lg3: "" } },
            [
              _c("material-stats-card", {
                attrs: {
                  color: "info",
                  icon: "mdi-twitter",
                  title: "Followers",
                  value: "+245",
                  "sub-icon": "mdi-update",
                  "sub-text": "Just Updated",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }